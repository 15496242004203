var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "videoEmbed" }, [
    _c(
      "video",
      {
        ref: "video",
        staticClass: "videoEmbed__video",
        attrs: {
          autoplay: _vm.autoplay,
          controls: _vm.controls,
          poster: _vm.poster === "" ? null : _vm.poster,
          loop: _vm.loop,
        },
        domProps: { muted: _vm.muted },
      },
      [
        _c("source", { attrs: { src: _vm.mediaFile, type: "video/mp4" } }),
        _vm._v(
          "\n        " + _vm._s(_vm.$t("video.browserNotSupported")) + "\n    "
        ),
      ]
    ),
    _vm._v(" "),
    !_vm.hideControls
      ? _c(
          "div",
          {
            staticClass: "videoEmbed__control",
            class: _vm.classes,
            on: { click: _vm.startPlaying },
          },
          [_vm._m(0)]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "videoEmbed__controlInner" }, [
      _c("span", {
        staticClass: "videoEmbed__controlInnerPlay videoEmbed__controlPlay",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }