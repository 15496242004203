




































export default {
    name: 'VideoEmbed',
    props: {
        autoplay: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: false
        },
        muted: {
            type: Boolean,
            default: true
        },
        loop: {
            type: Boolean,
            default: true
        },
        isHero: {
            type: Boolean,
            default: true
        },
        mediaFile: String,
        poster: String
    },
    data()
    {
        return {
            isPlaying: this.autoplay,
            hideControls: false
        };
    },
    computed: {
        classes()
        {
            return {
                'videoEmbed__control--hide': this.isPlaying
            };
        }
    },
    methods: {
        startPlaying()
        {
            this.isPlaying && !this.isHero ? this.$refs.video.pause() : this.$refs.video.play();
            this.isPlaying = !this.isPlaying;
            this.hideControls = this.isHero && this.isPlaying;
        }
    }

};
